import React from "react";

import SEO from "../components/seo";
import LocalizedLink from "../components/localized-link";
import Coordinators from "../components/coordinators";

import styles from "./faq.module.scss";

const FAQ = () => (
  <section className={styles.section}>
    <SEO title="Dažniausiai užduodami klausimai" robots="noindex, nofollow" />

    <h1>Dažniausiai užduodami klausimai</h1>

    <div className="bp-container">
      <h2>Stebėtojų pažymėjimai</h2>

      <h3>
        Ką daryti, jeigu su manimi niekas nesusisiekė ir neinformavo dėl stebėtojo pažymėjimo
        atsiėmimo?
      </h3>

      <ul className="bp-styled-list">
        <li>
          Stebėtojai patys gali susisiekti su savo savivaldybės koordinatoriumi (
          <Coordinators />) ir pasitikslinti dėl galimybės pasiimti pažymėjimą.
        </li>
        <li>
          Vilniaus stebėtojai ir asmenys, kurie sistemoje pažymėjo, kad pažymėjimą atsiims „Baltųjų
          pirštinių“ biure, gali atvykti į biurą adresu <b>NVO Avilys, Gedimino pr. 1</b>, bet kurią
          savaitės dieną 10-20 val.
        </li>
      </ul>

      <h2>Stebėjimo grafikai</h2>

      <h3>Ar galiu pats planuoti, kur ir kada stebėti, ar reikia derintis su koordinatoriais?</h3>

      <ul className="bp-styled-list">
        <li>
          Kiekvienas stebėtojas, žinodamas savo laiko galimybes, gali savarankiškai pasirinkti, kur
          ir kada vyks stebėti. Tai būtina padaryti naudojantis mūsų parengtais grafikais -
          pasirinkus balsavimo vietą ir laiką, reikia įrašyti savo vardą, pavardę tuščiuose baltuose
          langeliuose. Grafikus rasite savo paskyroje.
        </li>
        <li>
          Siekiame visas laiko galimybes maksimaliai išnaudoti, todėl koordinatoriai kasdien
          peržiūri stebėtojų sąrašą ir susisiekia su tais, kurie kitą dieną gali stebėti. Jie padeda
          supildyti grafiką, pasirinkti vietas stebėjimui. Kaip minėta, šio žingsnio nebūtina
          laukti, raginame planuotis patiems. :)
        </li>
      </ul>

      <h2>Stebėjimo klausimynai</h2>

      <h3>Kur, kada ir kaip dažnai pildyti stebėtojo klausimyną?</h3>

      <p>
        Klausimyną privaloma pildyti kiekvienoje balsavimo vietoje, kurioje stebite procesą. Atvykus
        į stebėjimo vietą atsidarykite Jums tinkamą klausimyną, peržvelkite klausimus ir stebėdami
        atkreipkite dėmesį į dalykus, apie kuriuos klausiama. Prieš išvykdami iš stebėjimo vietos
        užpildykite anketą.
      </p>

      <p>
        Balsavimo namuose metu vienas klausimynas yra pildomas po stebėjimo vienoje rinkimų
        apylinkėje - <em>ne po kiekvienų namų</em>.
      </p>

      <h2>Organizaciniai klausimai</h2>

      <h3>Kur rasti apygardų/apylinkių rinkimų komisijos narių/pirmininko kontaktus?</h3>

      <p>
        Rinkimų komisijų kontaktus rasite VRK tinklalapyje:{" "}
        <a
          href="https://www.vrk.lt/apygardos-apylinkes-komisiju-nariai-stebetojai-2024-pr"
          target="_blank"
          rel="noreferrer noopener"
          className="bp-link bp-accent"
        >
          https://www.vrk.lt/apygardos-apylinkes-komisiju-nariai-stebetojai-2024-pr
        </a>
        .
      </p>

      <h3>Ar bus kompensuojamos kuro išlaidos?</h3>

      <p>
        Taip, bet negalime pažadėti 100% išlaidų kompensavimo. Kadangi turime ribotą biudžetą,
        tiksli kompensavimo suma bus aiški tik po rinkimų, kai visi pateiks prašymus kompensavimui.
      </p>

      <p>Jeigu bus reikalingas kelionės išlaidų kompensavimas, būtinai:</p>

      <ul className="bp-styled-list">
        <li>pasirašykite savanorystės sutartį;</li>
        <li>
          vykdami stebėti sekite kilometražą - pasižymėkite automobilio ridos duomenis (kelionės
          pradžioje ir pabaigoje);
        </li>
        <li>
          stebėjimo dieną įsipilkite kuro tiek, kiek sunaudojote, ir išsaugokite čekį (rekvizitų ant
          jo nereikia);
        </li>
        <li>
          po rinkimų atsiųsime dokumentą dėl kompensavimo ir informaciją, kaip viską pateikti.
        </li>
      </ul>

      <h2>Pažeidimai</h2>

      <h3>Kur registruoti, kam pranešti apie galimą rinkimų pažeidimą?</h3>

      <ul className="bp-styled-list">
        <li>
          Jeigu galimas pažeidimas pastebimas balsavimo vietoje - apie tai informuok atsakingos
          apylinkės rinkimų komisiją.
        </li>
        <li>
          Jeigu pastebimas galimas rinkėjų papirkimas ar pavėžėjimas (pagrįstas - čia ir dabar
          matote labai įtartinus veiksmus) - pranešk policijai numeriu{" "}
          <a href="tel:112" className="bp-link bp-accent">
            112
          </a>
          .
        </li>
      </ul>

      <p>
        <strong className={styles.superStrong}>Visus</strong> galimus pažeidimus su įrodymais ir
        visomis surinktomis aplinkybėmis registruokite tinklalapyje:{" "}
        <LocalizedLink to="/report-violation" className="bp-link bp-accent">
          pranešti apie pažeidimą
        </LocalizedLink>
        .
      </p>

      <p>
        Konsultacijos ir pagalba teikiama telefonu{" "}
        <a href="tel:+37063313177" className="bp-link bp-accent">
          +37063313177
        </a>
        .
      </p>

      <h3>Ką daryti, jeigu rinkimų komisija nekreipia į mano pastabas dėmesio?</h3>

      <p>
        Esant grubiam pažeidimui apylinkėje ir atvejams, kai kuri nors rinkimų komisija nesutinka
        pašalinti pažeidimo, kai niekaip nepavyksta išspręsti problemos vietoje, stebėtojas turi
        teisę užpildyti protesto raštą ir pateikti jį apylinkės rinkimų komisijos pirmininkui.
      </p>

      <p>
        <a
          href="https://docs.google.com/document/d/1NF2hdVWfXjeBVWduF3a7243p5JZJTLWwmfDZKdmMR1Q"
          target="_blank"
          rel="noreferrer noopener"
          className="bp-link bp-accent"
        >
          Protesto rašto formą rasite čia
        </a>{" "}
        - atsispausdinkite ir turėkite su savimi stebėjimo metu. Jeigu užpildėte protesto raštą,
        visų pirma, jį nufotografuokite ir po to įteikite komisijos pirmininkui. Nufotografuotą
        raštą būtinai perduokite biurui{" "}
        <LocalizedLink to="/report-violation" className="bp-link bp-accent">
          užregistruojant pažeidimą tinklalapyje
        </LocalizedLink>{" "}
        ir prisegant rašto nuotrauką.
      </p>
    </div>
  </section>
);

export default FAQ;
