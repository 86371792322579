import React from "react";

import TRAINING from "../../content/training.json";

import SEO from "../components/seo";

const TrainingMaterial = () => (
  <section>
    <SEO title="Mokomoji medžiaga" robots="noindex, nofollow" />

    <h1 className="bp-heading">Mokomoji medžiaga</h1>

    <div className="bp-container bp-left">
      <ul className="bp-styled-list">
        <li>
          <a
            href={TRAINING.observerGuidelines}
            target="_blank"
            rel="noreferrer noopener"
            className="bp-link bp-accent"
          >
            Nepriklausomo rinkimų stebėtojo vadovas
          </a>{" "}
          - čia rasi praktinius patarimus, atsakingų asmenų kontaktus ir daug kitos naudingos
          informacijos.
        </li>
        <li>
          <a
            href={TRAINING.liveCourse}
            target="_blank"
            rel="noreferrer noopener"
            className="bp-link bp-accent"
          >
            „Baltųjų pirštinių“ stebėtojų nuotolinių mokymų įrašas
          </a>
          .
        </li>
        <li>
          <a
            href={TRAINING.electionLaw}
            target="_blank"
            rel="noreferrer noopener"
            className="bp-link bp-accent"
          >
            Aktualūs teisės aktai
          </a>
          .
        </li>
      </ul>
    </div>
  </section>
);

export default TrainingMaterial;
