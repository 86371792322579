export const QUESTIONNAIRES = {
  // =====SECOND PHASE START=====
  ADVANCE_MUNICIPALITY:
    "https://docs.google.com/forms/d/e/1FAIpQLSe_xy2c1SMfweG1KmzZ7-Vyu7YGPK-Zc3B5rfRCyTWyNHWMLw/viewform?usp=pp_url&entry.194097740=<<NAME>>&entry.685289548=<<PHONE>>",
  ADVANCE_SPECIAL:
    "https://docs.google.com/forms/d/e/1FAIpQLSdJcKvWfpuepFKUfPc2O-qUf2WiPUT9pMdkvlVpwQgYllphOQ/viewform?usp=pp_url&entry.2098988459=<<NAME>>&entry.1315407010=<<PHONE>>",
  ADVANCE_HOME:
    "https://docs.google.com/forms/d/e/1FAIpQLSf9nMzWCGYzbwE4U6EBVvDB9PjyRUZCH4jBcYkEJMaK2nMBzw/viewform?usp=pp_url&entry.2098988459=<<NAME>>&entry.1849708676=<<PHONE>>",
  ELECTION_DAY:
    "https://docs.google.com/forms/d/e/1FAIpQLSc7gpTU4wIUjDVeDHLJC78VgGuVl190XuoPdxZkURjpcQwgxA/viewform?usp=pp_url&entry.2098988459=<<NAME>>&entry.1391793873=<<PHONE>>",
  VOTE_COUNT:
    "https://docs.google.com/forms/d/e/1FAIpQLSfTbY2rd5iRK5wrkG6vlWkPjQk17YcUpjBXQbRDbfV3P8vOxw/viewform?usp=pp_url&entry.2098988459=<<NAME>>&entry.979519746=<<PHONE>>"
  // =====SECOND PHASE END=====
};
