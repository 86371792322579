import React, { useCallback } from "react";

import MUNICIPALITY from "../../enums/municipality.json";
import USER_STATUS from "../../enums/user-status.json";
import YES_NO from "../../enums/yes-no.json";

import { ELECTION_DATE_RANGE } from "../utils/calendar";
import { useTranslations } from "../hooks/translations";
import TableTimeFilter from "../components/table-time-filter";
import TablePage from "../components/table-page";

import { useUsers } from "./hooks/users";

const getAccessibleMunicipalities = claims =>
  !claims.ADMIN && !claims.NATIONAL_COORDINATOR && claims.COORDINATOR
    ? Object.keys(MUNICIPALITY).filter(key => claims.MUNICIPALITIES.includes(key))
    : Object.keys(MUNICIPALITY);

const doesAvailabilityMatch = (availability, selectedDate) =>
  availability.some(({ dateTime }) => dateTime.valueOf() === selectedDate.valueOf());

const doesAvailabilityMatchTime = (availability, dateFrom, dateTo) =>
  availability.some(({ fromTime, toTime }) => {
    if (fromTime < dateFrom) {
      return toTime > dateFrom;
    }
    return fromTime < dateTo;
  });

const COLUMNS = [
  {
    key: "id",
    accessor: "id",
    allowGlobalFilter: true
  },
  {
    key: "surname",
    accessor: "surname",
    allowGlobalFilter: true
  },
  {
    key: "name",
    accessor: "name",
    allowGlobalFilter: true
  },
  {
    key: "email",
    accessor: "email",
    allowGlobalFilter: true
  },
  {
    key: "phone",
    accessor: "phone",
    allowGlobalFilter: true
  },
  {
    key: "municipality",
    accessor: "municipality",
    filter: {
      comparator: "includesComparator",
      options: ({ claims }) => getAccessibleMunicipalities(claims),
      translationKey: "municipality",
      multiple: true
    },
    allowGlobalFilter: true
  },
  {
    key: "adult",
    accessor: "adult",
    filter: {
      comparator: "equalsComparator",
      options: Object.keys(YES_NO),
      translationKey: "yesNo",
      initialValue: YES_NO.YES
    }
  },
  {
    key: "status",
    accessor: "status",
    filter: {
      comparator: "includesComparator",
      options: Object.keys(USER_STATUS),
      translationKey: "userStatus",
      multiple: true
    }
  },
  {
    key: "additionalInfoAvailable",
    accessor: "additionalInfoAvailable",
    filter: {
      comparator: "equalsComparator",
      options: Object.keys(YES_NO),
      translationKey: "yesNo"
    }
  },
  {
    key: "trainingCompleted",
    accessor: "trainingCompleted",
    filter: {
      comparator: "equalsComparator",
      options: Object.keys(YES_NO),
      translationKey: "trainingStatus"
    }
  },
  {
    key: "licenceOrdered",
    accessor: "licenceOrdered",
    filter: {
      comparator: "equalsComparator",
      options: Object.keys(YES_NO),
      translationKey: "yesNo"
    }
  },
  {
    key: "hasLicence",
    accessor: "hasLicence",
    filter: {
      comparator: "equalsComparator",
      options: Object.keys(YES_NO),
      translationKey: "licenceStatus"
    }
  },
  {
    key: "car",
    accessor: "car",
    filter: {
      comparator: "equalsComparator",
      options: Object.keys(YES_NO),
      translationKey: "yesNo"
    }
  },
  {
    key: "organization",
    accessor: "organization"
  },
  {
    key: "registrationDate",
    accessor: "createdAtString"
  },
  {
    key: "personalCode",
    accessor: "personalCode",
    allowGlobalFilter: true
  },
  {
    key: "birthDate",
    accessor: "birthDateString"
  },
  {
    key: "licenceCollectionMethod",
    accessor: "licenceCollectionMethod"
  },
  {
    key: "experience",
    accessor: "experience"
  },
  {
    key: "activities",
    accessor: "activities"
  },
  {
    key: "otherHelp",
    accessor: "otherHelp"
  },
  {
    key: "scheduleMunicipality",
    accessor: "municipalities",
    filter: {
      comparator: (rows, [fieldName], selectedMunicipality) => {
        if (!selectedMunicipality) {
          return rows;
        }

        return rows.filter(row => {
          const municipalities = row.values[fieldName] || [row.values.municipality];
          return municipalities.includes(selectedMunicipality);
        });
      },
      options: ({ claims }) => getAccessibleMunicipalities(claims),
      translationKey: "municipality"
    }
  },
  {
    key: "scheduleDays",
    accessor: "availability",
    filter: {
      // eslint-disable-next-line react/prop-types, react/jsx-props-no-spreading
      component: ({ options, ...props }) => <TableTimeFilter dateOptions={options} {...props} />,
      comparator: (rows, [fieldName], value) => {
        if (!value || !value.date) {
          return rows;
        }

        if (!value.timeFrom && !value.timeTo) {
          const selectedDate = new Date(`${value.date}T00:00:00`);
          return rows.filter(row =>
            doesAvailabilityMatch(row.values[fieldName] || [], selectedDate)
          );
        }

        const dateFrom = value.timeFrom
          ? new Date(`${value.date}T${value.timeFrom}:00`)
          : new Date(`${value.date}T00:00:00`);

        const dateTo = value.timeTo
          ? new Date(`${value.date}T${value.timeFrom}:00`)
          : new Date(`${value.date}T23:59:59`);

        return rows.filter(row =>
          doesAvailabilityMatchTime(row.values[fieldName] || [], dateFrom, dateTo)
        );
      },
      options: ELECTION_DATE_RANGE
    }
  },
  {
    key: "secondPhase",
    accessor: "secondPhase",
    filter: {
      comparator: "equalsComparator",
      options: Object.keys(YES_NO),
      translationKey: "yesNo"
    }
  }
];

const HIDDEN_COLUMNS = [
  "id",
  "adult",
  "organization",
  "registrationDate",
  "status",
  "trainingCompleted",
  "licenceOrdered",
  "hasLicence",
  "car",
  "additionalInfoAvailable",
  "personalCode",
  "birthDate",
  "licenceCollectionMethod",
  "experience",
  "activities",
  "otherHelp",
  "scheduleMunicipality",
  "scheduleDays",
  "secondPhase"
];

const Volunteers = () => {
  const t = useTranslations("clientRoutes.volunteers");

  const { loading, users } = useUsers();

  const transformCSVRow = useCallback(
    row => ({
      ...row,
      availability: row.availability
        ? row.availability
            .map(
              ({ dateString, fromTimeString = "", toTimeString = "" }) =>
                `${dateString} ${fromTimeString}-${toTimeString}`
            )
            .join("\n")
        : ""
    }),
    []
  );

  return (
    <TablePage
      id="user-list"
      columns={COLUMNS}
      hiddenColumns={HIDDEN_COLUMNS}
      loading={loading}
      data={users}
      path="/dashboard/volunteers"
      title={t.title}
      heading={t.heading}
      filename={t.filename}
      headerTranslations={t}
      transformCSVRow={transformCSVRow}
    />
  );
};

export default Volunteers;
