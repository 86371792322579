import React, { useCallback, useState, useContext } from "react";
import { useDispatch } from "react-redux";

import YES_NO from "../../../enums/yes-no.json";

import { updateSecondPhase } from "../../api/volunteers";
import { reportError } from "../../utils/error-handler";
import { updateSelf as updateSelfAction } from "../../state/self/self-actions";
import InfoPopupContext from "../../info-popup-context";
import Button from "../../components/button";
import StatusModal from "../../components/status-modal";

import styles from "./second-phase-confirmation.module.scss";

const SecondPhaseConfirmation = () => {
  const infoPopupContext = useContext(InfoPopupContext);

  const [submitting, setSubmitting] = useState(false);

  const [error, setError] = useState(false);

  const dispatch = useDispatch();

  const submitForm = useCallback(
    confirm => {
      if (submitting) {
        return;
      }

      setSubmitting(true);

      updateSecondPhase(confirm)
        .then(
          () => {
            infoPopupContext.display({
              message: "Pasirinkimas išsaugotas"
            });
            setTimeout(() => {
              dispatch(
                updateSelfAction({
                  secondPhase: confirm ? YES_NO.YES : YES_NO.NO,
                  secondPhaseConfirmed: true
                })
              );
            });
          },
          err => {
            reportError(err);
            setError(true);
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
    [submitting, dispatch, setError, setSubmitting]
  );

  return (
    <>
      <h1 className="bp-heading">Labas, „Baltųjų pirštinių“ stebėtoja(u)!</h1>

      <p className={`bp-paragraph bp-center ${styles.question}`}>
        Ar stebėsi antrąjį Respublikos Prezidento rinkimų turą gegužės 13-19 dienomis?
      </p>

      <p className="bp-center">
        <Button
          className={`bp-standard ${styles.button}`}
          onClick={() => submitForm(true)}
          busy={submitting}
        >
          Taip!
        </Button>

        <Button
          className={`bp-inverse ${styles.button}`}
          onClick={() => submitForm(false)}
          busy={submitting}
        >
          Ne
        </Button>
      </p>

      {error ? (
        <StatusModal
          error
          heading="Nepavyko išsaugoti pasirinkimo"
          description="Apgailestaujame, bet nepavyko išsaugoti Tavo pasirinkimo. Susisiek su mumis el. paštu ir mes padėsime išspręsti šią problemą."
          onClose={() => setError(false)}
        />
      ) : null}
    </>
  );
};

export default SecondPhaseConfirmation;
