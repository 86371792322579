import React, { useCallback, useContext, useState } from "react";

import { generateCertificate } from "../utils/certificate";
import { useSelf } from "../hooks/self";
import InfoPopupContext from "../info-popup-context";
import SEO from "../components/seo";
import Button from "../components/button";

import styles from "./certificate.module.scss";

const Certificate = () => {
  const { userData } = useSelf();

  const [isGenerating, setIsGenerating] = useState(false);

  const infoPopupContext = useContext(InfoPopupContext);

  const downloadCertificate = useCallback(() => {
    setIsGenerating(true);

    generateCertificate(userData)
      .catch(err => {
        infoPopupContext.display({
          error: true,
          message: "Nepavyko sugeneruoti sertifikato"
        });
        throw err;
      })
      .finally(() => {
        setIsGenerating(false);
      });
  }, [userData]);

  return (
    <section>
      <SEO title="Sertifikatas" robots="noindex, nofollow" />

      <h1>Stebėtojo sertifikatas</h1>

      <div className="bp-container">
        <Button
          onClick={downloadCertificate}
          busy={isGenerating}
          className={`bp-standard ${styles.button}`}
        >
          Atsisiųsti sertifikatą
        </Button>

        <p>
          Ačiū Tau už skirtą laiką ir nuoširdžią savanorystę vardan skaidrių rinkimų! Susitiksime
          kituose rinkimuose!
        </p>

        <p>„Baltųjų pirštinių“ komanda</p>
      </div>
    </section>
  );
};

export default Certificate;
