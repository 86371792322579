import ELECTION_DAY from "../enums/election-day.json";
import REGION from "../enums/region.json";

export const SCHEDULES = [
  {
    type: ELECTION_DAY.ADVANCE_MUNICIPALITY,
    region: REGION.ALYTUS,
    file:
      "https://docs.google.com/spreadsheets/d/1c9VKhsihiWM4c4bHO240IUfnsY3AfiYsS9fbkBvRw0s/edit#gid=1227216766"
  },
  {
    type: ELECTION_DAY.ADVANCE_SPECIAL,
    region: REGION.ALYTUS,
    file:
      "https://docs.google.com/spreadsheets/d/1c9VKhsihiWM4c4bHO240IUfnsY3AfiYsS9fbkBvRw0s/edit#gid=880480228"
  },
  {
    type: ELECTION_DAY.ADVANCE_HOME,
    region: REGION.ALYTUS,
    file:
      "https://docs.google.com/spreadsheets/d/1c9VKhsihiWM4c4bHO240IUfnsY3AfiYsS9fbkBvRw0s/edit#gid=614812332"
  },
  {
    type: ELECTION_DAY.ELECTION_DAY,
    region: REGION.ALYTUS,
    file:
      "https://docs.google.com/spreadsheets/d/1c9VKhsihiWM4c4bHO240IUfnsY3AfiYsS9fbkBvRw0s/edit#gid=1990570320"
  },
  {
    type: ELECTION_DAY.ADVANCE_MUNICIPALITY,
    region: REGION.KAUNAS,
    file:
      "https://docs.google.com/spreadsheets/d/1H3INy0q7WCM4qgbrq16x5FGSwMPlRT8XBeAWPCI2DKc/edit#gid=1659891683"
  },
  {
    type: ELECTION_DAY.ADVANCE_SPECIAL,
    region: REGION.KAUNAS,
    file:
      "https://docs.google.com/spreadsheets/d/1H3INy0q7WCM4qgbrq16x5FGSwMPlRT8XBeAWPCI2DKc/edit#gid=146528259"
  },
  {
    type: ELECTION_DAY.ADVANCE_HOME,
    region: REGION.KAUNAS,
    file:
      "https://docs.google.com/spreadsheets/d/1H3INy0q7WCM4qgbrq16x5FGSwMPlRT8XBeAWPCI2DKc/edit#gid=684822838"
  },
  {
    type: ELECTION_DAY.ELECTION_DAY,
    region: REGION.KAUNAS,
    file:
      "https://docs.google.com/spreadsheets/d/1H3INy0q7WCM4qgbrq16x5FGSwMPlRT8XBeAWPCI2DKc/edit#gid=1386472245"
  },
  {
    type: ELECTION_DAY.ADVANCE_MUNICIPALITY,
    region: REGION.KLAIPEDA,
    file:
      "https://docs.google.com/spreadsheets/d/1pFgizE2XdPsX-y56ofhQz-O6qFM7R7I9tGmSI4GQeTI/edit#gid=1023294926"
  },
  {
    type: ELECTION_DAY.ADVANCE_SPECIAL,
    region: REGION.KLAIPEDA,
    file:
      "https://docs.google.com/spreadsheets/d/1pFgizE2XdPsX-y56ofhQz-O6qFM7R7I9tGmSI4GQeTI/edit#gid=822429221"
  },
  {
    type: ELECTION_DAY.ADVANCE_HOME,
    region: REGION.KLAIPEDA,
    file:
      "https://docs.google.com/spreadsheets/d/1pFgizE2XdPsX-y56ofhQz-O6qFM7R7I9tGmSI4GQeTI/edit#gid=1681362903"
  },
  {
    type: ELECTION_DAY.ELECTION_DAY,
    region: REGION.KLAIPEDA,
    file:
      "https://docs.google.com/spreadsheets/d/1pFgizE2XdPsX-y56ofhQz-O6qFM7R7I9tGmSI4GQeTI/edit#gid=639392939"
  },
  {
    type: ELECTION_DAY.ADVANCE_MUNICIPALITY,
    region: REGION.MARIJAMPOLE,
    file:
      "https://docs.google.com/spreadsheets/d/1EEQrMthsqGQxkqy0ZPpaypjOjX8jG1fI8kz7siW_cJw/edit#gid=1523628093"
  },
  {
    type: ELECTION_DAY.ADVANCE_SPECIAL,
    region: REGION.MARIJAMPOLE,
    file:
      "https://docs.google.com/spreadsheets/d/1EEQrMthsqGQxkqy0ZPpaypjOjX8jG1fI8kz7siW_cJw/edit#gid=2040423293"
  },
  {
    type: ELECTION_DAY.ADVANCE_HOME,
    region: REGION.MARIJAMPOLE,
    file:
      "https://docs.google.com/spreadsheets/d/1EEQrMthsqGQxkqy0ZPpaypjOjX8jG1fI8kz7siW_cJw/edit#gid=787706036"
  },
  {
    type: ELECTION_DAY.ELECTION_DAY,
    region: REGION.MARIJAMPOLE,
    file:
      "https://docs.google.com/spreadsheets/d/1EEQrMthsqGQxkqy0ZPpaypjOjX8jG1fI8kz7siW_cJw/edit#gid=116131449"
  },
  {
    type: ELECTION_DAY.ADVANCE_MUNICIPALITY,
    region: REGION.PANEVEZYS,
    file:
      "https://docs.google.com/spreadsheets/d/1YQ0Rmc1OxdeeFERnlK_KyxrfxmUaJWKmPMqzLL3GDA0/edit#gid=1818798031"
  },
  {
    type: ELECTION_DAY.ADVANCE_SPECIAL,
    region: REGION.PANEVEZYS,
    file:
      "https://docs.google.com/spreadsheets/d/1YQ0Rmc1OxdeeFERnlK_KyxrfxmUaJWKmPMqzLL3GDA0/edit#gid=1316763281"
  },
  {
    type: ELECTION_DAY.ADVANCE_HOME,
    region: REGION.PANEVEZYS,
    file:
      "https://docs.google.com/spreadsheets/d/1YQ0Rmc1OxdeeFERnlK_KyxrfxmUaJWKmPMqzLL3GDA0/edit#gid=549034850"
  },
  {
    type: ELECTION_DAY.ELECTION_DAY,
    region: REGION.PANEVEZYS,
    file:
      "https://docs.google.com/spreadsheets/d/1YQ0Rmc1OxdeeFERnlK_KyxrfxmUaJWKmPMqzLL3GDA0/edit#gid=1721848189"
  },
  {
    type: ELECTION_DAY.ADVANCE_MUNICIPALITY,
    region: REGION.SIAULIAI,
    file:
      "https://docs.google.com/spreadsheets/d/1ce_8K3lRU2FAclPhfD6HKuQk2u38R53tv2yrnF1kOqw/edit#gid=627654618"
  },
  {
    type: ELECTION_DAY.ADVANCE_SPECIAL,
    region: REGION.SIAULIAI,
    file:
      "https://docs.google.com/spreadsheets/d/1ce_8K3lRU2FAclPhfD6HKuQk2u38R53tv2yrnF1kOqw/edit#gid=585123719"
  },
  {
    type: ELECTION_DAY.ADVANCE_HOME,
    region: REGION.SIAULIAI,
    file:
      "https://docs.google.com/spreadsheets/d/1ce_8K3lRU2FAclPhfD6HKuQk2u38R53tv2yrnF1kOqw/edit#gid=1231644587"
  },
  {
    type: ELECTION_DAY.ELECTION_DAY,
    region: REGION.SIAULIAI,
    file:
      "https://docs.google.com/spreadsheets/d/1ce_8K3lRU2FAclPhfD6HKuQk2u38R53tv2yrnF1kOqw/edit#gid=556562734"
  },
  {
    type: ELECTION_DAY.ADVANCE_MUNICIPALITY,
    region: REGION.TAURAGE,
    file:
      "https://docs.google.com/spreadsheets/d/12d9pzoY_c_B5H5KajEfX8YBZTY52EkwWK_6AC5dPvr4/edit#gid=1171484459"
  },
  {
    type: ELECTION_DAY.ADVANCE_SPECIAL,
    region: REGION.TAURAGE,
    file:
      "https://docs.google.com/spreadsheets/d/12d9pzoY_c_B5H5KajEfX8YBZTY52EkwWK_6AC5dPvr4/edit#gid=1519413518"
  },
  {
    type: ELECTION_DAY.ADVANCE_HOME,
    region: REGION.TAURAGE,
    file:
      "https://docs.google.com/spreadsheets/d/12d9pzoY_c_B5H5KajEfX8YBZTY52EkwWK_6AC5dPvr4/edit#gid=100483016"
  },
  {
    type: ELECTION_DAY.ELECTION_DAY,
    region: REGION.TAURAGE,
    file:
      "https://docs.google.com/spreadsheets/d/12d9pzoY_c_B5H5KajEfX8YBZTY52EkwWK_6AC5dPvr4/edit#gid=665779935"
  },
  {
    type: ELECTION_DAY.ADVANCE_MUNICIPALITY,
    region: REGION.TELSIAI,
    file:
      "https://docs.google.com/spreadsheets/d/1Cheb9XWfD6l43JAAZQG8__HmdWgclkIqs_4i3_GMYO0/edit#gid=1258833720"
  },
  {
    type: ELECTION_DAY.ADVANCE_SPECIAL,
    region: REGION.TELSIAI,
    file:
      "https://docs.google.com/spreadsheets/d/1Cheb9XWfD6l43JAAZQG8__HmdWgclkIqs_4i3_GMYO0/edit#gid=1953577659"
  },
  {
    type: ELECTION_DAY.ADVANCE_HOME,
    region: REGION.TELSIAI,
    file:
      "https://docs.google.com/spreadsheets/d/1Cheb9XWfD6l43JAAZQG8__HmdWgclkIqs_4i3_GMYO0/edit#gid=1993878076"
  },
  {
    type: ELECTION_DAY.ELECTION_DAY,
    region: REGION.TELSIAI,
    file:
      "https://docs.google.com/spreadsheets/d/1Cheb9XWfD6l43JAAZQG8__HmdWgclkIqs_4i3_GMYO0/edit#gid=1456982473"
  },
  {
    type: ELECTION_DAY.ADVANCE_MUNICIPALITY,
    region: REGION.UTENA,
    file:
      "https://docs.google.com/spreadsheets/d/1BRSVwW7sR3RcxLf73JG4GGN_jz59yvKhgP2i5ASVIF0/edit#gid=635017387"
  },
  {
    type: ELECTION_DAY.ADVANCE_SPECIAL,
    region: REGION.UTENA,
    file:
      "https://docs.google.com/spreadsheets/d/1BRSVwW7sR3RcxLf73JG4GGN_jz59yvKhgP2i5ASVIF0/edit#gid=2038458624"
  },
  {
    type: ELECTION_DAY.ADVANCE_HOME,
    region: REGION.UTENA,
    file:
      "https://docs.google.com/spreadsheets/d/1BRSVwW7sR3RcxLf73JG4GGN_jz59yvKhgP2i5ASVIF0/edit#gid=1144117413"
  },
  {
    type: ELECTION_DAY.ELECTION_DAY,
    region: REGION.UTENA,
    file:
      "https://docs.google.com/spreadsheets/d/1BRSVwW7sR3RcxLf73JG4GGN_jz59yvKhgP2i5ASVIF0/edit#gid=268867326"
  },
  {
    type: ELECTION_DAY.ADVANCE_MUNICIPALITY,
    region: REGION.VILNIUS,
    file:
      "https://docs.google.com/spreadsheets/d/1PZrg6sQf4HPTy1oMfepmj2dCjmDb1OBypckyuBr5R10/edit#gid=547248417"
  },
  {
    type: ELECTION_DAY.ADVANCE_SPECIAL,
    region: REGION.VILNIUS,
    file:
      "https://docs.google.com/spreadsheets/d/1PZrg6sQf4HPTy1oMfepmj2dCjmDb1OBypckyuBr5R10/edit#gid=2078865800"
  },
  {
    type: ELECTION_DAY.ADVANCE_HOME,
    region: REGION.VILNIUS,
    file:
      "https://docs.google.com/spreadsheets/d/1PZrg6sQf4HPTy1oMfepmj2dCjmDb1OBypckyuBr5R10/edit#gid=277749554"
  },
  {
    type: ELECTION_DAY.ELECTION_DAY,
    region: REGION.VILNIUS,
    file:
      "https://docs.google.com/spreadsheets/d/1PZrg6sQf4HPTy1oMfepmj2dCjmDb1OBypckyuBr5R10/edit#gid=552362854"
  }
];
