import React from "react";

import MUNICIPALITY from "../../../enums/municipality.json";
import LICENCE_COLLECTION_METHOD from "../../../enums/licence-collection-method.json";

import { useSelf } from "../../hooks/self";
import { useTranslations } from "../../hooks/translations";
import ButtonLink from "../../components/button-link";
import Coordinators from "../../components/coordinators";

const Licence = () => {
  const enums = useTranslations("enums");

  const { userData } = useSelf();

  const collectFromMainOffice =
    userData.licenceCollectionMethod === LICENCE_COLLECTION_METHOD.MAIN_OFFICE;

  const municipalityIsMainOffice = userData.municipality === MUNICIPALITY.VILNIUS;

  const municipalityName = enums.municipality[userData.municipality];

  return (
    <>
      <p className="bp-paragraph">
        Sveikiname sėkmingai išlaikius nepriklausomo rinkimų stebėtojo žinių testą! Dabar telieka
        atsiimti stebėtojo pažymėjimą ir pirmyn į rinkimus.
      </p>

      {/* eslint-disable no-nested-ternary */}
      {municipalityIsMainOffice ? (
        <p className="bp-paragraph">
          Artimiausiu metu VRK pagamins ir pateiks mums Tavo pažymėjimą. Gavę pažymėjimą
          informuosime Tave, atsiimti jį galėsi mūsų biure adresu{" "}
          <b>NVO Avilys, Gedimino pr. 1, Vilnius</b> (viršutinis aukštas). Jei kils problemų,
          skambink mums telefonu{" "}
          <a href="tel:+37063313177" className="bp-link">
            +37063313177
          </a>
          .
        </p>
      ) : collectFromMainOffice ? (
        <>
          <p className="bp-paragraph">
            Artimiausiu metu VRK pagamins ir pateiks mums Tavo pažymėjimą, kurį pasirinkai atsiimti
            „Baltųjų pirštinių“ biure Vilniuje, adresu <b>NVO Avilys, Gedimino pr. 1, Vilnius</b>{" "}
            (viršutinis aukštas). Gavę pažymėjimą informuosime Tave. Jei kils problemų, skambink
            mums telefonu{" "}
            <a href="tel:+37063313177" className="bp-link">
              +37063313177
            </a>
            .
          </p>

          <p className="bp-paragraph">
            <strong>
              Jei persigalvojai ir norėtum pažymėjimą atsiimti iš savo savivaldybės (
              <b>{municipalityName}</b>) koordinatoriaus,
              <strong>iki pirmadienio vakaro</strong> informuok mus paštu{" "}
              <a href="mailto:taidele@baltosiospirstines.lt" className="bp-link">
                taidele@baltosiospirstines.lt
              </a>
              .
            </strong>
          </p>
        </>
      ) : (
        <>
          <p className="bp-paragraph">
            Artimiausiu metu VRK pagamins ir pateiks mums Tavo pažymėjimą. Gavę pažymėjimą išsiųsime
            jį Tavo savivaldybės (<b>{municipalityName}</b>
            ) koordinatoriui (
            <Coordinators />) ir pranešime Tau apie tai el. paštu.
          </p>
        </>
      )}
      {/* eslint-enable no-nested-ternary */}

      {/* =====SECOND PHASE START===== */}
      <p className="bp-paragraph">
        O kol lauki pažymėjimo, kviečiame pradėti planuoti savo stebėjimo grafiką rinkimų savaitės
        metu!
      </p>

      <p className="bp-center">
        <ButtonLink to="/dashboard/schedules" className="bp-standard">
          Stebėjimo grafikai
        </ButtonLink>
      </p>
      {/* =====SECOND PHASE END===== */}
    </>
  );
};

export default Licence;
