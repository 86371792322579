import React from "react";

import TRAINING from "../../../content/training.json";

import styles from "./training.module.scss";

const Training = () => (
  <>
    <h1 className="bp-heading">Mokymai ir žinių patikrinimas</h1>

    <h2 className={styles.heading}>
      Kiekvienas profesionalus stebėtojas turi tinkamai pasiruošti rinkimams. Mūsų siūlomas mokymosi
      planas:
    </h2>

    <ol className={styles.steps}>
      <li>
        Mokykis žiūrėdamas rinkimų stebėtojų mokymų įrašą sau patogiu metu per šią nuorodą:{" "}
        <a
          href={TRAINING.liveCourse}
          target="_blank"
          rel="noreferrer noopener"
          className="bp-link bp-accent"
        >
          {TRAINING.liveCourse}
        </a>
        .
      </li>
      <li>
        Savarankiškai susipažink ir įsigilink į rinkimų teisę -{" "}
        <a
          href={TRAINING.electionLaw}
          target="_blank"
          rel="noreferrer noopener"
          className="bp-link bp-accent"
        >
          aktualius teisės aktus rasi čia
        </a>
        .
      </li>
      <li>
        Susipažink ir naudokis{" "}
        <a
          href={TRAINING.observerGuidelines}
          target="_blank"
          rel="noreferrer noopener"
          className="bp-link bp-accent"
        >
          Nepriklausomo rinkimų stebėtojo vadovu
        </a>{" "}
        - jame praktiniai patarimai, atsakingų asmenų kontaktai ir daug kitos naudingos informacijos
        mūsų stebėtojams.
      </li>
    </ol>

    <h2 className={styles.heading}>
      Prieš tampant stebėtoju būtina pasitikrinti savo žinias apie rinkimus!
    </h2>

    <p className="bp-paragraph">
      Siekiame, kad rinkimai būtų stebimi maksimaliai kokybiškai - prisidėk prie profesionalumo
      patikrindamas savo žinias. <b>Visi asmenys</b>, ketinantys būti nepriklausomais rinkimų
      stebėtojais, privalo užpildyti žinių patikrinimo testą.
    </p>

    <p className="bp-paragraph">
      <a
        href={TRAINING.test}
        target="_blank"
        rel="noreferrer noopener"
        className="bp-link bp-accent"
      >
        Pildyti rinkimų stebėtojo testą.
      </a>
    </p>
  </>
);

export default Training;
