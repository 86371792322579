import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchUsers } from "../../state/users/user-actions";
import { useSelf } from "../../hooks/self";
import { useTranslations } from "../../hooks/translations";

const translateUser = (user, enums) => {
  const {
    activities,
    car,
    experience,
    licenceCollectionMethod,
    municipalities,
    municipality,
    scheduleVoteCount,
    status,
    trainingCompleted,
    licenceOrdered,
    hasLicence,
    adult,
    additionalInfoAvailable,
    secondPhase
  } = user;

  return {
    ...user,
    activities: activities ? activities.map(key => enums.activity[key]) : activities,
    car: enums.yesNo[car],
    experience: experience ? experience.map(key => enums.experience[key]) : experience,
    licenceCollectionMethod: enums.licenceCollectionMethod[licenceCollectionMethod],
    municipalities: municipalities
      ? municipalities.map(key => enums.municipality[key])
      : municipalities,
    municipality: enums.municipality[municipality],
    scheduleVoteCount: enums.yesNo[scheduleVoteCount],
    status: enums.userStatus[status],
    trainingCompleted: enums.trainingStatus[trainingCompleted],
    licenceOrdered: enums.yesNo[licenceOrdered],
    hasLicence: enums.licenceStatus[hasLicence],
    adult: enums.yesNo[adult],
    additionalInfoAvailable: enums.yesNo[additionalInfoAvailable],
    secondPhase: enums.yesNo[secondPhase],
    raw: {
      status,
      additionalInfoAvailable,
      trainingCompleted,
      licenceOrdered,
      hasLicence,
      adult
    }
  };
};

const translateUsers = (users, enums) => users.map(user => translateUser(user, enums));

const selector = state => state.users;

export const useUsers = () => {
  const enums = useTranslations("enums");

  const { claims } = useSelf();

  /* eslint-disable consistent-return */
  const fetchInput = useMemo(() => {
    if (claims.ADMIN) {
      return {
        withPrivateInfo: true
      };
    }

    if (!claims.NATIONAL_COORDINATOR && !claims.ADMIN && claims.COORDINATOR) {
      return {
        municipalities: claims.MUNICIPALITIES
      };
    }
  }, [claims]);
  /* eslint-enable consistent-return */

  const dispatch = useDispatch();

  const { loading, users, error } = useSelector(selector);

  useEffect(() => {
    if (!users) {
      dispatch(fetchUsers(fetchInput));
    }
  }, []);

  const translatedUsers = useMemo(
    () => (users && users.length > 0 ? translateUsers(users, enums) : users),
    [users]
  );

  return {
    loading,
    users: translatedUsers,
    error
  };
};

export const useUser = userId => {
  const { loading, users, error } = useUsers();

  return {
    loading,
    user: users ? users.find(({ id }) => id === userId) : null,
    error
  };
};
