import React from "react";
import PropTypes from "prop-types";

import importantLocationExample from "../../assets/images/important-location-example.png";
import protectedLocationExample from "../../assets/images/protected-location-example.png";

import LocalizedLink from "../../components/localized-link";
import Modal from "../../components/modal";
import Coordinators from "../../components/coordinators";

import styles from "./instructions.module.scss";

const ScheduleInstructionsModal = ({ onClose }) => (
  <Modal onClose={onClose} className={styles.modal}>
    <div className={`bp-left ${styles.instructions}`}>
      <h2>Kaip naudotis grafikais?</h2>

      <ul className="bp-styled-list">
        <li>
          Prieš vykstant stebėti balsavimo Tau reikia bendrame grafike pasižymėti, kur ir kada
          planuoji būti:
          <ol className={styles.steps}>
            <li>sąraše pagal savivaldybę / apskritį ir datą susirask reikiamą grafiką;</li>
            <li>grafike susirask punktą / apylinkę, kur nori stebėti balsavimą;</li>
            <li>atitinkamame laukelyje įrašyk savo vardą / pavardę;</li>
            <li>
              jei yra laukelis, skirtas įrašyti stebėjimo datą ir / arba laiką (pvz. „Diena ir
              laikas, kada stebėsite“), įrašyk, kada planuoji ten būti.
            </li>
          </ol>
        </li>
        <li className={styles.importantLocationExample}>
          <img src={importantLocationExample} alt="Prioritetinės lokacijos pavyzdys" />
          &nbsp;-&nbsp;Balsavimo lokacijos, pažymėtos raudona spalva, reiškia, kad tai yra rizikinga
          vieta. Šių vietų stebėjimas - prioritetas.
        </li>
        <li className={styles.importantLocationExample}>
          <img src={protectedLocationExample} alt="Prioritetinės lokacijos pavyzdys" />
          &nbsp;-&nbsp;Norint patekti į balsavimo lokacijas, pažymėtas žalia spalva, gali reikėti
          papildomų dokumentų. Jei nori stebėti balsavimą tokioje vietoje, susisiek su savo
          koordinatoriumi (
          <Coordinators />
          ).
        </li>
      </ul>
    </div>

    <div className={`bp-left ${styles.instructions}`}>
      <h2>Svarbu!</h2>

      <ul className="bp-styled-list">
        <li>
          <strong>
            Išankstinis balsavimas spec. punktuose ir namuose yra probleminis, todėl be galo prašome
            skirti kiek įmanoma daugiau laiko jo stebėjimui.
          </strong>
        </li>
        <li>
          <strong>
            Būtinai pateik užpildytą{" "}
            <LocalizedLink to="/dashboard/questionnaires" className={`bp-link ${styles.textLink}`}>
              stebėtojo klausimyną
            </LocalizedLink>{" "}
            po apsilankymo kiekvienoje balsavimo vietoje.
          </strong>
        </li>
      </ul>
    </div>
  </Modal>
);

ScheduleInstructionsModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ScheduleInstructionsModal;
